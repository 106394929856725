import React, { useRef, useEffect, useState } from "react";
import "../CSS/Services.css";
import services1 from "../Assets/services1.png";
import ring from "../Assets/ring.png";
import square from "../Assets/square.png";
import coins from "../Assets/coins.png";
import number from "../Assets/number.png";
import text from "../Assets/omnibux2.png";
import text2 from "../Assets/text.png"
import services2 from "../Assets/services2.png";
import card1 from "../Assets/card1.png";
import card2 from "../Assets/card2.png";
import services_mobile from "../Assets/services-mobile.png";
import flow from "../Assets/flow.png";
import breaker1 from "../Assets/AJ_InfoTeck/Images/phone 2.png";
import breaker2 from "../Assets/AJ_InfoTeck/Images/phone 1.png";
import breaker3 from "../Assets/AJ_InfoTeck/Images/phone 3.png";
import omni_bottom from "../Assets/omni-bottom.png";
import VanillaTilt from "vanilla-tilt";
import about2 from "./../Assets/AJ_InfoTeck/Images/aj_logo.png"
import { Center } from "@mantine/core";
import Aos from "aos";

// Images
import Web_Development from "../Assets/AJ_InfoTeck/Images/Web_Development.jpg"
import Influence_Marketing from "../Assets/AJ_InfoTeck/Images/Influncer_marketing2.jpg"
import AppDevelopement from "../Assets/AJ_InfoTeck/Images/AppDevelopment.jpg"
import Media_Marketing from "../Assets/AJ_InfoTeck/Images/Media Marketing.jpg"
import { useMediaQuery } from "@mantine/hooks";

function Services() {
  // Window Scroll To Top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // Aos Init 
  Aos.init()

  // useReff 
  const img1 = useRef(null)
  const img2 = useRef(null)
  const img3 = useRef(null)
  const img4 = useRef(null)
  const img5 = useRef(null)
  const img6 = useRef(null)
  const img7 = useRef(null)
  const img8 = useRef(null)
  const img9 = useRef(null)
  const img10 = useRef(null)
  const img11 = useRef(null)
  const img12 = useRef(null)
  const img13 = useRef(null)

  // useState 
  const [image, setImage] = useState(img1)

  useEffect(() => {
    VanillaTilt.init(image.current, {
      max: 30,
      speed: 1000,
      scale: 1.2,
      glare: false,
    });
  }, [image]);

  const Query = useMediaQuery('(max-width:500px)')

  return (
    <div className="services-main">
      <div className="services-top">
        <div className="services-left " ref={img1} onMouseOver={() => setImage(img1)}
          data-aos='fade-right' data-aos-delay="500">
          <img src={services1} className="services" alt="img" />
          <img src={ring} className="ring" alt="img" />
          <img src={square} className="square" alt="img" />
          <img src={coins} className="coins" alt="img" />
          <img src={number} className="number" alt="img" />
          <img src={text2} className="text2" alt="img"></img>
        </div>
        <div className="services-right">
          <h3 className="services-top-content" data-aos='fade-left' data-aos-delay="500" >
            The All in One Social Networking
            <br /> Platform For All Your Hyper Local
            <br />
            Social Needs
          </h3>
        </div>
        <Center className="brand_logo" data-aos='zoom-in' data-aos-delay="500" >
          <img src={about2} alt="img" />
        </Center>
      </div>
      <div className="home-sub-div">
        <h3
          className="home-sub-title scale_1"
          data-aos='zoom-in'
          data-aos-delay="500" >AJ Info Teck</h3>
      </div>

      {/* Services Second Section */}
      <div className="services-second-section">
        <div className="services-second-left">
          <div>
            <p className="services-second-content" data-aos='fade-right' data-aos-delay="500" >
              <span className="sub-title">AJ Info Teck </span> is an all in one{" "}
              <br />
              social networking platform that <br />
              connects nightlife industries <br />
              like bars, clubs, and food &<br /> beverage industry restaurants,
              <br /> cafes, walk in business
              <br /> altogether linking the consumer <br />
              goods industry through creating a <br />
              common collusive marketplace.
              <br /> with a universal social
              <br />
              networking currency to avail
              <br />
              discount creadits. Tailor made
              <br />
              for interpersonal and <br />
              hyperlocal networking.
            </p>
            {/* <button className="services-second-btn" data-aos='fade-right' data-aos-delay="500" >
              Join Exclusive OG-list
            </button> */}
          </div>
        </div>
        <div className="services-second-right">
          <img
            data-aos='fade-left' data-aos-delay="500"
            alt="img"
            // ref={img2} onMouseOver={() => setImage(img2)}
            src={services2}
          ></img>
        </div>
      </div>

      {/* Services SignUp Section */}
      <div className="signup-main">
        <div className="signup-top">
          {/* <img alt="img" src={Vector} className="vector1" data-aos='fade-right' data-aos-delay="500" /> */}
          <div>
            <h3 className="signup-title" data-aos='zoom-in' data-aos-delay="500" >
              Sign Up to be the First Exclusive Members of
              <span> AJ Info Teck</span>
            </h3>
            <div className="signup-form">
              <button data-aos='fade-right' data-aos-delay="500" >Join Whitelist</button>
              <input disabled type="text" placeholder="Input Email Here" data-aos='fade-left' data-aos-delay="500" />
            </div>
          </div>
          {/* <img alt="img" src={Vector} className="vector2" data-aos='fade-left' data-aos-delay="500" /> */}
        </div>
      </div>
      <div className="signup-two">
        <div className="signup-card-div">
          <h3 className="signup-title-two" data-aos='zoom-in' data-aos-delay="500" >
            {/* "Swipe Match, Vibe, Meet, Repeat" */}
          </h3>
          <div className="signup-card-left">
            <img className="signup-card-left-app" alt="img" data-aos='fade-right' data-aos-delay="500" src={AppDevelopement} ref={img3} onMouseOver={() => setImage(img3)} />
          </div>
          <div className="signup-card-right">
            <p>
              Our app development services deliver cutting-edge solutions
              tailored to your business needs. With a focus on user experience
              and functionality, we create intuitive and engaging mobile
              applications that captivate your audience. Unlock the potential
              of the mobile market and connect with your customers on-the-go.
            </p>
          </div>
        </div>
        <div className="signup-card-div card-div-two">
          <div className="signup-card-right">
            <p>
              With our logo-focused social media marketing
              strategies, we leverage the power of visual storytelling
              to enhance brand visibility. By strategically incorporating
              your logo into engaging social media content, we drive brand
              recognition, foster customer loyalty, and create a cohesive
              online presence.
            </p>
          </div>
          <div className="signup-card-left ">
            <img alt="img" data-aos='fade-right' data-aos-delay="500" src={Media_Marketing} ref={img5} onMouseOver={() => setImage(img5)} />
          </div>
        </div>
      </div>
      <div className="image service-mobiles">
        <img alt="img" data-aos='fade-up' data-aos-delay="500" src={services_mobile} ref={img7} onMouseOver={() => setImage(img7)} />
      </div>
      <div className="bcg_linear1" >
        <div>
          <h1 data-aos='zoom-in' data-aos-delay="500"  >Smooth & Seamless End User Flow</h1>
        </div>
        <div className="flow_diagram" ref={img13} onMouseOver={() => setImage(img13)}>
          <img alt="img" src={flow} data-aos='zoom-in' data-aos-delay="500" />
        </div>
      </div>
      <div className="omnibux-section">
        <div className="omni-second-div">
          <div className="omni-left">
            <p data-aos='fade-right' data-aos-delay="500">
              " Bringing your business to
              {Query ? '' : <br />}
              the web can open up
              {Query ? '' : <br />}
              new opportunities for {Query ? '' : <br />}
              growth and reach"
            </p>
          </div>
          <div className="omni-right" >
            <div className="red" ref={img8} onMouseOver={() => setImage(img8)} data-aos='fade-left' data-aos-delay="500">
              <img src={Web_Development} alt="img" />
            </div>
          </div>
        </div>
        <div className="omni-second-div omni-bottom-div">
          <div className="omni-left omni-left-two">
            <div className="red">
              <img src={Influence_Marketing} alt="img" />
            </div>
          </div>
          <div className="omni-right omni-right-two">
            <p data-aos='fade-left' data-aos-delay="500">
              "Incorporating influencer
             {Query ? "" : <br />}
              marketing into your{Query ? "" : <br />}
              business strategy can
             {Query ? "" : <br />}
              provide several benefits."
            </p>
          </div>
        </div>
      </div>

      {/* Icebreaker Section */}
      <div className="breaker-main">
        <div className="omni-btm-div" ref={img9}
          onMouseOver={() => setImage(img9)}>
          <img alt="img" data-aos='zoom-in' data-aos-delay="500" src={omni_bottom} className="omni-bottom" />
        </div>
        <div className="breaker-top">
          <div className="breaker-left">
            <h3 data-aos='fade-right' data-aos-delay="500">
              Interactive
              {Query ? "" : <br />}
              Icebreaker {Query ? "" : <br />}
              Sessions
            </h3>
          </div>
          <div className="breaker-right">
            <h3 data-aos='fade-left' data-aos-delay="500">
              Interactive Icebreaker
              {Query ? "" : <br />}
              Sessions To Find The {Query ? "" : <br />}
              Vibe Of Your Clique
            </h3>
          </div>
        </div>
        <div className="breaker-img-flex">
          <img alt="img" src={breaker1} ref={img10}
            data-aos='fade-right' data-aos-delay="500"
            onMouseOver={() => setImage(img10)} className="breaker1" />
          <img alt="img" src={breaker2} ref={img11}
            data-aos='zoom-in' data-aos-delay="500"
            onMouseOver={() => setImage(img11)} className="breaker2" />
          <img alt="img" src={breaker3} ref={img12}
            data-aos='fade-left' data-aos-delay="500"
            onMouseOver={() => setImage(img12)} className="breaker3" />
        </div>
      </div>
    </div>
  );
}
export default Services;
