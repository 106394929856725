import React, { useEffect, useState, useRef } from 'react'
import contact from "../Assets/contact.png";

// Email Js
import emailjs from '@emailjs/browser';

// Mantine UI
import { showNotification } from '@mantine/notifications';

// icon
import { CircleCheck, X } from 'tabler-icons-react';
import { ThemeIcon } from '@mantine/core';


const Contact = () => {
    // Window Scroll To Top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // useState
    const [userData, setUserData] = useState({
        email_id: "",
        name: "",
        subject: "",
        Enquiry: ""
    })


    const form = useRef();


    // Email Js 
    const sendEmail = (e) => {
        e.preventDefault();
        if (userData.email_id == '' || userData.Enquiry == '' || userData.name == '' || userData.subject == '') {
            showNotification({
                message: "Please Fill all The Inputs",
                icon: <ThemeIcon variant="light" radius="xl" size="50px" color="red">
                    <X color="red" size={30} />
                </ThemeIcon>,
                autoClose: 2000,
                style: { padding: '1rem', fontSize: '205px' }
            })
        }
        else {
            emailjs.sendForm('service_yscld3s', 'template_zir660t', form.current, '9SJu7KBkenAvvYrMp')
                .then((result) => {
                    showNotification({
                        message: "Email Send Successfully",
                        icon: <ThemeIcon variant="light" radius="xl" size="xl" color="green">
                            <CircleCheck color="green" />
                        </ThemeIcon>
                    })
                    setInterval(() => {
                        window.location.reload()
                    }, 2000)
                }, (error) => {
                    showNotification({
                        message: "Error Occured in Email Sending",
                        icon: <ThemeIcon variant="light" radius="xl" size="xl" color="red">
                            <X color="red" />
                        </ThemeIcon>
                    })
                })
        }
    };


    return (
        <div>
            <div className="contact-main">
                <form ref={form} className="contact-form" onSubmit={sendEmail} method='POST'>
                    <h3 className="contact-title" data-aos="zoom-in" data-aos-delay="100">Contact us</h3>
                    <div className="contact-div">
                        <h4 data-aos="fade-right" data-aos-delay="100" className="contact-sub-title">Email </h4>
                        <input
                            onChange={(e) => setUserData({ ...userData, email_id: e.target.value })}
                            data-aos="fade-left" data-aos-delay="100"
                            type="text"
                            placeholder="Input Email Here"
                            className="contact-input"
                            name='user_email'
                            value={userData.email_id}
                        ></input>
                    </div>
                    <div className="contact-div">
                        <h4 className="contact-sub-title" data-aos="fade-right" data-aos-delay="100">Name </h4>
                        <input
                            onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                            data-aos="fade-left" data-aos-delay="100"
                            type="text"
                            placeholder="Input Name Here"
                            className="contact-input"
                            name='user_name'
                            value={userData.name}
                        ></input>
                    </div>
                    <div className="contact-div">
                        <h4 data-aos="fade-right" data-aos-delay="100" className="contact-sub-title">Service</h4>
                        <input
                            onChange={(e) => setUserData({ ...userData, subject: e.target.value })}
                            data-aos="fade-left" data-aos-delay="100"
                            type="text"
                            placeholder="Input Subject Here"
                            className="contact-input"
                            name='service'
                            value={userData.subject}
                        ></input>
                    </div>
                    <div className="contact-div">
                        <h4 data-aos="fade-right" data-aos-delay="100" className="contact-sub-title">Enquiry</h4>
                        <input
                            onChange={(e) => setUserData({ ...userData, Enquiry: e.target.value })}
                            data-aos="fade-left" data-aos-delay="100"
                            type="text"
                            placeholder="Input Description Here"
                            className="description"
                            name='enquery'
                            value={userData.Enquiry}
                        ></input>
                    </div>
                    <button data-aos="zoom-in" data-aos-delay="100" className="contact-btn">Send enquiry</button>
                </form>
                {/* <img data-aos="zoom-in" data-aos-delay="100" src={contact} className="card-img"></img> */}
            </div>
        </div>
    )
}

export default Contact