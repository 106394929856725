import React, { useEffect } from 'react'
import "../CSS/Blog.css"


// Mantine UI
import { Card, Center, Grid, Text, Title } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

// Images
import Movie_Making from "../Assets/AJ_InfoTeck/Images/Movie_Making.jpg"
import Post_Production1 from "../Assets/AJ_InfoTeck/Images/post procduction 1.png"
import Post_Production2 from "../Assets/AJ_InfoTeck/Images/post procduction 2.png"
import Jingles from "../Assets/AJ_InfoTeck/Images/Jingles.jpg"
import Voice_Over from "../Assets/AJ_InfoTeck/Images/Voice_Over.jpg"
import Flex from "../Assets/AJ_InfoTeck/Images/Flex.jpg"

const Blog = () => {
  // Window Scroll To Topz
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const adMaking = [
    {
      title: `Cinematic Video Shoot `,
      para: `
      Our cinematic video shoot services bring your brand's story to life 
      with cinematic flair and captivating visuals. With a talented team 
      of videographers and state-of-the-art equipment, we create stunning 
      videos that engage and inspire your audience. Harness the power of 
      cinematic storytelling and make a lasting impact with our professional 
      video production services.`,
      img: Movie_Making
    },
    {
      title: `CGI Graphics & VFX `,
      para: ` 
      Our CGI graphics and VFX services take your advertising to new dimensions 
      by seamlessly blending reality and imagination. With cutting-edge technology 
      and a team of skilled artists, we create stunning visual effects and 
      computer-generated imagery that mesmerize your audience. Elevate your 
      brand's visual presence and leave a lasting impression with our expert 
      CGI graphics and VFX capabilities. `,
      img: Post_Production1
    },
    {
      title: `Post Production `,
      para: `Our post-production services elevate your advertisement to its 
      fullest potential through meticulous editing, color grading, and sound 
      design. With attention to detail and creative expertise, we enhance the 
      visual and auditory aspects of your content, delivering a polished and 
      impactful final product. Trust us to bring your vision to life and make 
      your advertisement stand out from the competition.`,
      img: Post_Production2
    },
    {
      title: `Voice Over `,
      para: `Our professional voice-over services provide the perfect audio 
      accompaniment to your advertisement, capturing the attention and imagination 
      of your audience. With a diverse pool of talented voice actors and exceptional 
      recording quality, we bring depth and emotion to your brand's message. Elevate 
      your advertisement with a compelling voice-over that resonates with your target 
      audience.`,
      img: Voice_Over
    },
    {
      title: `Jingles `,
      para: `Our captivating jingle creation services turn your brand's essence into a 
      memorable musical experience. With catchy melodies and clever lyrics, we create 
      jingles that resonate with your audience and leave a lasting impression. Elevate 
      your brand's advertising with a unique and infectious jingle that sets you apart 
      from the competition.`,
      img: Jingles
    },
    {
      title: `Flex Print `,
      para: `Our flex print services offer vibrant and high-quality printing solutions 
      for your advertising needs. With attention to detail and superior printing technology, 
      we bring your designs to life on flexible materials, ensuring excellent durability 
      and visual impact. Trust us to deliver stunning flex prints that effectively promote 
      your brand and captivate your audience.`,
      img: Flex
    },
  ]

  const Query = useMediaQuery('(max-width:1060px)')
  const Query1600 = useMediaQuery('(max-width:1380px)')
  const Query768 = useMediaQuery('(max-width:768px)')
  return (
    <div className='ad-making-page'>
      {/* Section 2  */}

      <section>
        <div className='ad-title-div'>
          <Center>
            <Title className='ad-making-title'>
              Advertisement Making & Designing
            </Title>
          </Center>
        </div>
        <div>
          <br />
          <Grid className='ad-making-grid1'>
            {adMaking.map((value, index) => {
              return (
                <>
                  <Grid.Col span={Query ? Query768 ? '' : 5 : 4} key={index} className='ad-making-grid2-span'
                  >
                    <Card
                      className='ad-making-grid1-card'
                      mih={Query768 ? '' : Query1600 ? 585 : 500}
                      maw={500}
                      mah={Query ? Query768 ? "auto" : 510 : 510}
                      shadow="sm"
                      padding="lg"
                      radius="md"
                      withBorder >
                      <Card.Section>
                        <Center>
                          <img src={value.img} alt='img' className='ad-marketing-image' />
                        </Center>
                      </Card.Section>
                      <div style={{ padding: "1rem", textOverflow: "hidden" }}>
                        <Center>
                          <Title order={2} className='ad-title'>
                            {value.title}
                          </Title>
                        </Center>
                        <Text>
                          {value.para}
                        </Text>
                      </div>
                    </Card>
                  </Grid.Col>
                </>
              )
            })}
          </Grid>
        </div>
      </section>

      {/* Section 2  End */}
    </div>
  )
}

export default Blog
