import React from "react";
import "../CSS/Footer.css";
import footer_logo from "../Assets/AJ_InfoTeck/Logo/Logo.png";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";

function Footer() {

  const Query = useMediaQuery('(max-width:1060px)')
  return (
    <div className="footer-main">
      <div className="footer-left" data-aos="fade-right" data-aos-delay="100">
        <div className="footer-left-div" >
          <img alt="img" src={footer_logo} className="footer-logo"></img>
          <h3>AJ Info Teck</h3>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3943.9142674928726!2d77.7326928!3d8.699691599999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b04135e313d5495%3A0xb07b24533e63b70d!2sAJ%20INFOTECK!5e0!3m2!1sen!2sin!4v1689330560086!5m2!1sen!2sin"
          width="600"
          height="450"
          loading="lazy"
          className="footer-map-iframe"
          referrerpolicy="no-referrer-when-downgrade"></iframe>
        {/* <p className="footer-content">"the social network Venture"</p> */}
      </div>
      {Query ? <br /> : ''}
      <div className="footer-right-two" data-aos="zoom-in" data-aos-delay="100">
        <h3>Newsletter</h3>
        <div className="gap_10">
          <input
            type="text"
            placeholder="Your Mail"
            className="newsletter-input"
          />
          <button className="newsletter-btn">Submit</button>
        </div>
      </div>
      <div className="footer-right" data-aos="fade-left" data-aos-delay="100">
        <h3>Quick Links</h3>
        <ul>
          <Link to="/" className="links">
            <li> Home
            </li></Link>
          <Link to="/services" className="links"><li>Services </li></Link>
          <Link to="/about" className="links"><li>About </li></Link>
          <Link to="/adMaking" className="links"><li>Ad Making </li></Link>
          <Link to="/marketing" className="links"><li>Marketing </li></Link>
          <Link to="/contact" className="links"><li>Contact </li></Link >
        </ul >
      </div >
    </div >
  );
}
export default Footer;
