import React, { useRef, useEffect, useState } from "react";
import about4 from "../Assets/AJ_InfoTeck/Images/phone 2.png";
import "../CSS/About.css";
import VanillaTilt from "vanilla-tilt";
import Aos from "aos";
// import CardImage from "../Assets/services1.png"

// Email Js
import emailjs from '@emailjs/browser';

// Mantine UI
import { showNotification } from '@mantine/notifications';

// icon
import { CircleCheck, X } from 'tabler-icons-react';
import { ThemeIcon } from '@mantine/core';

function About() {
  // Window Scroll To Topz
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // UseRef
  const img1 = useRef(null)

  //useState
  const [image, setImage] = useState(img1)

  // Vanilla Tilt in useEffect
  useEffect(() => {
    VanillaTilt.init(image.current, {
      max: 30,
      speed: 1000,
      scale: 1.2,
      glare: false,
    })
  }, [image])

  // Aos Init
  Aos.init()

  // useState
  const [userData, setUserData] = useState({
    email_id: "",
    name: "",
    subject: "",
    Enquiry: ""
  })

  const form = useRef();


  // Email Js 
  const sendEmail = (e) => {
    e.preventDefault();
    if (userData.email_id == '' || userData.Enquiry == '' || userData.name == '' || userData.subject == '') {
      showNotification({
        message: "Please Fill all The Inputs",
        icon: <ThemeIcon variant="light" radius="xl" size="50px" color="red">
          <X color="red" size={30} />
        </ThemeIcon>,
        autoClose: 2000,
        style: { padding: '1rem', fontSize: '205px' }
      })
    }
    else {
      emailjs.sendForm('service_yscld3s', 'template_zir660t', form.current, '9SJu7KBkenAvvYrMp')
        .then((result) => {
          showNotification({
            message: "Email Send Successfully",
            icon: <ThemeIcon variant="light" radius="xl" size="xl" color="green">
              <CircleCheck color="green" />
            </ThemeIcon>
          })
          setInterval(() => {
            window.location.reload()
          }, 2000)
        }, (error) => {
          showNotification({
            message: "Error Occured in Email Sending",
            icon: <ThemeIcon variant="light" radius="xl" size="xl" color="red">
              <X color="red" />
            </ThemeIcon>
          })
        })
    }
  };


  return (
    <div className="about-main">
      <div className="about-second-section">
        <div className="abt-second-left">
          <div data-aos='fade-right' data-aos-delay="500">
            <div className="abt-second-div">
              <h3>About</h3>
            </div>
            <p className="abt-second-content">
              "We play the role a Doctor
              <br />
              for your brand, the one stop
              <br />
              solution for early stage
              <br />
              ventures, scaling ventures or
              <br />
              even to ignition a venture <br />
              from scratch"
            </p>
            {/* <button className="abt-second-btn scale_1">Join Exclusive OG-list</button> */}
          </div>
        </div>
        <div className="about-second-right">
          <img alt="img" ref={img1}
            data-aos='fade-left' data-aos-delay="500"
            onMouseOver={() => setImage(img1)} src={about4}></img>
        </div>
      </div>
      {/* Mission Section */}
      <div className="mission-main">
        <div className="mission-left">
          <div data-aos='fade-right' data-aos-delay="500">
            <h2 className="mission-title">Vision</h2>
            <p className="mission-content ">
              "In the future of digital marketing, <br />
              personalized experiences <br />
              and targeted messaging <br />
              experiences and targeted messaging <br />
              will become even more essential. <br />
              Automation and AI-powered technologies <br />
              will streamline marketing <br />
              processes and improve efficiency.
            </p>
          </div>
        </div>
        <div className="mission-right">
          <div data-aos='fade-left' data-aos-delay="500">
            <h2 className="mission-title">Mission</h2>
            <p className="mission-content">
              We at AJ Info Teck make it our
              <br />
              Mission to
            </p>
            <p className="mission-content ">
              "Consistency learn and <br />
              exponentially innovate and <br />
              access new blue oceans of
              <br />
              markets to create first mover
              <br />
              advantage solutions.
            </p>
          </div>
        </div>
      </div>
      {/* Contact Section */}
      <div className="contact-main">
        <form ref={form} className="contact-form" onSubmit={sendEmail} method='POST'>
                    <h3 className="contact-title" data-aos="zoom-in" data-aos-delay="100">Contact us</h3>
                    <div className="contact-div">
                        <h4 data-aos="fade-right" data-aos-delay="100" className="contact-sub-title">Email </h4>
                        <input
                            onChange={(e) => setUserData({ ...userData, email_id: e.target.value })}
                            data-aos="fade-left" data-aos-delay="100"
                            type="text"
                            placeholder="Input Email Here"
                            className="contact-input"
                            name='user_email'
                            value={userData.email_id}
                        ></input>
                    </div>
                    <div className="contact-div">
                        <h4 className="contact-sub-title" data-aos="fade-right" data-aos-delay="100">Name </h4>
                        <input
                            onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                            data-aos="fade-left" data-aos-delay="100"
                            type="text"
                            placeholder="Input Name Here"
                            className="contact-input"
                            name='user_name'
                            value={userData.name}
                        ></input>
                    </div>
                    <div className="contact-div">
                        <h4 data-aos="fade-right" data-aos-delay="100" className="contact-sub-title">Service</h4>
                        <input
                            onChange={(e) => setUserData({ ...userData, subject: e.target.value })}
                            data-aos="fade-left" data-aos-delay="100"
                            type="text"
                            placeholder="Input Subject Here"
                            className="contact-input"
                            name='service'
                            value={userData.subject}
                        ></input>
                    </div>
                    <div className="contact-div">
                        <h4 data-aos="fade-right" data-aos-delay="100" className="contact-sub-title">Enquiry</h4>
                        <input
                            onChange={(e) => setUserData({ ...userData, Enquiry: e.target.value })}
                            data-aos="fade-left" data-aos-delay="100"
                            type="text"
                            placeholder="Input Description Here"
                            className="description"
                            name='enquery'
                            value={userData.Enquiry}
                        ></input>
                    </div>
                    <button data-aos="zoom-in" data-aos-delay="100" className="contact-btn">Send enquiry</button>
                </form>
        {/* <img alt="img" src={CardImage} data-aos='zoom-in' data-aos-delay="500" className="card-img"></img> */}
      </div>
    </div >
  );
}
export default About;
