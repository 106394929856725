import React, { useState } from "react";
import "../CSS/Navbar.css";
import { Link } from "react-router-dom";
import logo from "../Assets/AJ_InfoTeck/Logo/Logo.png";
function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [navList, setNavlist] = useState(true)
  const navLinks = document.querySelectorAll(".nav-items > a");

  navLinks.forEach((link) => {
    link.addEventListener("click", () => {
      const navMenu = document.querySelector(".nav-items");
      navMenu.classList.remove("open");
      setIsOpen(false);
    });
  });

  return (
    <div className="Navbar">
      <div className=" nav-top-flex">
        <div>
          <Link to="/">
            <img src={logo} className="nav-logo" alt="ima"></img>
          </Link>
        </div>
          <div className={`nav-items  ${isOpen && "open"}`}>
            <Link onMouseOver={() => setNavlist(false)} onMouseOut={() => setNavlist(true)} className={`${navList && 'linear-gradient'} `} to="/">Home</Link>
            <Link onMouseOver={() => setNavlist(false)} onMouseOut={() => setNavlist(true)} className={`${navList && 'linear-gradient'} `} to="/services">Service</Link>
            <Link onMouseOver={() => setNavlist(false)} onMouseOut={() => setNavlist(true)} className={`${navList && 'linear-gradient'} `} to="/about">About</Link>
            <Link onMouseOver={() => setNavlist(false)} onMouseOut={() => setNavlist(true)} className={`${navList && 'linear-gradient'} `} to="/adMaking">Ad Making</Link>
            <Link onMouseOver={() => setNavlist(false)} onMouseOut={() => setNavlist(true)} className={`${navList && 'linear-gradient'} `} to="/marketing">Marketing</Link>
            <Link onMouseOver={() => setNavlist(false)} onMouseOut={() => setNavlist(true)} className={`${navList && 'linear-gradient'} `} to="/contact">Contact</Link>
          </div>
        <div
          className={`nav-toggle ${isOpen && "open"}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="bar"></div>
        </div>
      </div>
    </div>
  );
}
export default Navbar;
