import React, { useRef, useState, useEffect } from "react";
import "../CSS/Home.css";
import home from "../Assets/home.png";
import home2 from "../Assets/home2.png";
import key from "../Assets/key.png";
import event from "../Assets/event.png";
import symbol1 from "../Assets/symbol1.png";
import symbol2 from "../Assets/symbol2.png";
import market1 from "../Assets/market1.png";
import market_top from "../Assets/market-top.png";
import process1 from "../Assets/process1.png";
import social from "../Assets/social.png";
import corporate from "../Assets/corporate.png";
import design from "../Assets/design.png";
import hands from "../Assets/hands.png";
import about2 from "../Assets/AJ_InfoTeck/Images/aj_logo.png";
import VanillaTilt from "vanilla-tilt"
import Aos from "aos";
import { Center } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";


// Email Js
import emailjs from '@emailjs/browser';

// Mantine UI
import { showNotification } from '@mantine/notifications';

// icon
import { CircleCheck, X } from 'tabler-icons-react';
import { ThemeIcon } from '@mantine/core';


function Home() {
  // Window Scroll To Top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // AOS Init
  Aos.init()

  // USeRef
  const img1 = useRef(null)
  const img2 = useRef(null)
  const img3 = useRef(null)
  const img4 = useRef(null)
  const img5 = useRef(null)
  const img6 = useRef(null)
  const img7 = useRef(null)
  const img8 = useRef(null)
  const img9 = useRef(null)

  // USeState
  const [isZoomed, setIsZoomed] = useState(false);
  const [refChange, setRefChange] = useState(img1)

  // UseEffect
  useEffect(() => {
    VanillaTilt.init(refChange.current, {
      max: 30,
      speed: 1000,
      scale: 1.2,
      glare: false,
    });
  }, [refChange]);

  // Mantine Query
  const Query = useMediaQuery("(max-width:500px)")

  // useState
  const [userData, setUserData] = useState({
    email_id: "",
    name: "",
    subject: "",
    Enquiry: ""
  })

  const form = useRef();


  // Email Js 
  const sendEmail = (e) => {
    e.preventDefault();
    if (userData.email_id == '' || userData.Enquiry == '' || userData.name == '' || userData.subject == '') {
      showNotification({
        message: "Please Fill all The Inputs",
        icon: <ThemeIcon variant="light" radius="xl" size="50px" color="red">
          <X color="red" size={30} />
        </ThemeIcon>,
        autoClose: 2000,
        style: { padding: '1rem', fontSize: '205px' }
      })
    }
    else {
      emailjs.sendForm('service_yscld3s', 'template_zir660t', form.current, '9SJu7KBkenAvvYrMp')
        .then((result) => {
          showNotification({
            message: "Email Send Successfully",
            icon: <ThemeIcon variant="light" radius="xl" size="xl" color="green">
              <CircleCheck color="green" />
            </ThemeIcon>
          })
          setInterval(() => {
            window.location.reload()
          }, 2000)
        }, (error) => {
          showNotification({
            message: "Error Occured in Email Sending",
            icon: <ThemeIcon variant="light" radius="xl" size="xl" color="red">
              <X color="red" />
            </ThemeIcon>
          })
        })
    }
  };


  return (
    <div className="home-main">
      <div className="home-top" >
        <div ref={img1} className="home-left" data-tilt data-aos="zoom-in" data-aos-delay="500">
          <img alt="img" src={home} onMouseOver={() => setRefChange(img1)} className="home-img"></img>
        </div>

        <div className="home-right" >
          <h3 className="home-top-content" data-aos="zoom-in" data-aos-delay="500">
            Bringing to You an End to End Solution
            {Query ? "" : <br />}
            For All Your Brand Needs
          </h3>
        </div>
        <Center className="about_brand_logo">
          <img
            className="home_btm_img"
            src={about2}
            alt="Ige" />
          <span className="shadow"></span>
        </Center>
      </div>
      <div className="home-sub-div">
        <h3 className="home-sub-title"
          data-tilt data-aos="zoom-in"
          data-aos-delay="100">AJ Info Teck</h3>
      </div>

      {/* Home Second Section */}
      <div className="home-second">
        <div data-tilt data-aos="zoom-in" data-aos-delay="100">
          <img
            src={home2}
            ref={img2}
            onMouseOver={() => setRefChange(img2)}
            className="home-two-img"
            alt="Images"
          ></img>
        </div>
      </div>
      {/* Home Third Section */}
      <div className="home-third-main">
        <div className="home-third-left">
          <h3 data-aos="fade-right" data-aos-delay="100" >
            Every Brand Has Its Uniquec {Query ? "" : <br />}
            Challenges That Require Unique {Query ? "" : <br />}
            Solution
          </h3>
        </div>
        <div className="home-third-right">
          <img alt="img" data-aos="fade-left" data-aos-delay="100" onMouseOver={() => setRefChange(img3)} ref={img3} src={key}></img>
        </div>
      </div>

      {/* Marketing Section */}
      <div className="market-section">
        <div className="market-left">
          <h3 data-aos="fade-right" data-aos-delay="100">
            Digital
            <br />
            Marketing
          </h3>
        </div>
        <div className="market-right">
          <img data-aos="fade-left"

            data-aos-delay="100" ref={img4}
            onMouseOver={() => setRefChange(img4)}
            alt="Img"
            src={market1}></img>
        </div>
        <img
          data-aos="zoom-in" data-aos-delay="500"
          src={market_top}
          ref={img5}
          onMouseOver={() => setRefChange(img5)}
          className="market-top"
          alt="img"
        />
      </div>

      {/* Events Bottom */}
      <div className="event-bottom-main">
        <div className="event-btm-content tilt">
          <img src={symbol1} className="symbol1 tilt" alt="img"></img>
          <p data-aos="zoom-in" data-aos-delay="200">
            A global creative agency bringing to you the
            {Query ? "" : <br />}
            essence of Design, Development Until Delivery
            {Query ? "" : <br />}
            to exponentially your busines
          </p>
          <img src={symbol2} className="symbol2" alt="img"></img>
        </div>
      </div>

      {/* Social Media Section */}
      <div className="social-main">
        <div className="social-left">
          <div className="social-left-img">
            <img data-aos="fade-right" alt="img" data-aos-delay="100" ref={img6} src={social}></img>
          </div>

        </div>
        <div className="social-right">
          <h3 data-aos="fade-left" data-aos-delay="100">
            Social Media
            <br />
            Marketing
          </h3>
        </div>
      </div>
      {/* Process Section */}
      <div className="process-main">
        <div className="process-left">
          <img data-aos="fade-right" alt="img" data-aos-delay="100" src={process1}></img>
        </div>
        <div className="process-right" data-aos="fade-left" data-aos-delay="100">
          <h3>Tailored Solutions for Your Venture</h3>
          <p>
            We believe that every business is {Query ? '' : <br />}
            unique, and your website should
            {Query ? '' : <br />}
            reflect that. Our team takes the time
            {Query ? '' : <br />}
            to understand your brand, goals, and
            {Query ? '' : <br />}
            target audience. We tailor our web {Query ? '' : <br />}
            development service to meet your
            {Query ? '' : <br />}
            specific needs, creating a{Query ? '' : <br />} customised solution that aligns
            with
            {Query ? '' : <br />}
            your vision and drives tangible
            {Query ? '' : <br />}
            results.
          </p>
        </div>
      </div>

      {/* Corporate Section */}
      <div className="corporate-main">
        <div className="corporate-left">
          <h3 data-aos="fade-right" data-aos-delay="100">
            Corporate
            <br />
            Branding
          </h3>
        </div>
        <div className="corporate-right">
          <img
            data-aos="fade-left" data-aos-delay="100"
            alt="img"
            src={corporate}
            ref={img7}
            onMouseOver={() => setRefChange(img7)}
            className={isZoomed ? "corporate-zoomed" : "corporate"}
          />
        </div>
      </div>
      {/* Global Creative Section */}
      <div className="event-bottom-main">
        <div className="event-btm-content">
          <img src={symbol1} className="symbol1" alt="img"></img>
          <p data-aos="zoom-in" data-aos-delay="100">
            Crafting a strategy branding and setting {Query ? "" : <br />}
            standardizations throughout all areas of the
            {Query ? "" : <br />}
            value chain of the venture
          </p>
          <img src={symbol2} className="symbol2" alt="img"></img>
        </div>
      </div>

      {/* Design And Development */}
      <div className="design-main">
        <div className="design-left" data-aos="fade-right" data-aos-delay="100"  >
          <img src={design} className="tv" alt="img" />
          <img src={about2} className="tv-logo" ref={img8}
            onMouseOver={() => setRefChange(img8)} alt="img"></img>
        </div>
        <div className="design-right">
          <h3 data-aos="fade-left" data-aos-delay="100">
            Web & App
            <br />
            Development
          </h3>
        </div>
      </div>
      {/* Corporate Bottom */}
      <div className="event-bottom-main">
        <div className="event-btm-content">
          <img src={symbol1} className="symbol1" alt="img"></img>
          <p data-aos="zoom-in" data-aos-delay="100">
            Materialising a corporate brand identity with set {Query ? "" : <br />}
            branding rules and guideline to effectively
            {Query ? "" : <br />}
            brand and market the venture
          </p>
          <img src={symbol2} className="symbol2" alt="img"></img>
        </div>
      </div>

      {/* Event Section */}
      <div className="event-main">
        <div className="event-left">
          <img ref={img9}
            data-aos="fade-right" data-aos-delay="100" alt="img"
            onMouseOver={() => setRefChange(img9)} src={event}></img>
        </div>
        <div className="event-right">
          <h3 data-aos="fade-left" data-aos-delay="100">
            Influencer
            <br />
            Marketing
          </h3>
        </div>
      </div>

      {/* Design Bottom */}
      <div className="event-bottom-main for-hands">
        <div className="event-btm-content">
          <img src={symbol1} className="symbol1" alt="img"></img>
          <p data-aos="zoom-in" data-aos-delay="100">
            Solving Strategic, product and scaling blockers <br />
            for ventures of all forms and sizes
          </p>
          <img src={symbol2} className="symbol2" alt="img"></img>
        </div>
      </div>
      {/* Home Enquiry Form */}

      <div className="contact-main home-contact-form">
        <form ref={form} className="contact-form" onSubmit={sendEmail} method='POST'>
          <h3 className="contact-title" data-aos="zoom-in" data-aos-delay="100">Contact us</h3>
          <div className="contact-div">
            <h4 data-aos="fade-right" data-aos-delay="100" className="contact-sub-title">Email </h4>
            <input
              onChange={(e) => setUserData({ ...userData, email_id: e.target.value })}
              data-aos="fade-left" data-aos-delay="100"
              type="text"
              placeholder="Input Email Here"
              className="contact-input"
              name='user_email'
              value={userData.email_id}
            ></input>
          </div>
          <div className="contact-div">
            <h4 className="contact-sub-title" data-aos="fade-right" data-aos-delay="100">Name </h4>
            <input
              onChange={(e) => setUserData({ ...userData, name: e.target.value })}
              data-aos="fade-left" data-aos-delay="100"
              type="text"
              placeholder="Input Name Here"
              className="contact-input"
              name='user_name'
              value={userData.name}
            ></input>
          </div>
          <div className="contact-div">
            <h4 data-aos="fade-right" data-aos-delay="100" className="contact-sub-title">Service</h4>
            <input
              onChange={(e) => setUserData({ ...userData, subject: e.target.value })}
              data-aos="fade-left" data-aos-delay="100"
              type="text"
              placeholder="Input Subject Here"
              className="contact-input"
              name='service'
              value={userData.subject}
            ></input>
          </div>
          <div className="contact-div">
            <h4 data-aos="fade-right" data-aos-delay="100" className="contact-sub-title">Enquiry</h4>
            <input
              onChange={(e) => setUserData({ ...userData, Enquiry: e.target.value })}
              data-aos="fade-left" data-aos-delay="100"
              type="text"
              placeholder="Input Description Here"
              className="description"
              name='enquery'
              value={userData.Enquiry}
            ></input>
          </div>
          <button data-aos="zoom-in" data-aos-delay="100" className="contact-btn">Send enquiry</button>
        </form>
        {/* <img data-aos="zoom-in" data-aos-delay="100"  alt="img" src={contact} className="card-img"></img> */}
      </div>
    </div >
  );
}
export default Home;
