import React, { useEffect } from 'react'
import "../CSS/Blog.css"

// Mantine UI
import { Card, Center, Grid, Text, Title } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

//Images
// import image from "../Assets/event.png"
import Seo from "../Assets/AJ_InfoTeck/Images/SEO.jpg"
import LogoDesign from "../Assets/AJ_InfoTeck/Images/DESIGN.png"
import Market from "../Assets/AJ_InfoTeck/Images/tYPES.jpg"
import WebDevelopment from "../Assets/AJ_InfoTeck/Images/post production.png"
import Branding from "../Assets/AJ_InfoTeck/Images/Promotion.png"
import AppDevelopment from "../Assets/AJ_InfoTeck/Images/App.png"
import GraphicsDesign from "../Assets/AJ_InfoTeck/Images/Designer.png"
import Email from "../Assets/AJ_InfoTeck/Images/Email_Marketing.jpg"
import Influncer from "../Assets/AJ_InfoTeck/Images/Influncer_Marketing.jpg";


const Marketing = () => {
    // Window Scroll To Topz
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const digitalMarketing = [
        {
            title: `Branding`,
            para: `Our digital marketing services are designed 
          to elevate your brand to new heights. With strategic 
          planning, compelling content, and targeted campaigns, 
          we amplify your brand's reach 
          and engage your audience effectively. Experience the 
          transformative power of digital marketing and make 
          your brand shine in the digital realm.`,
            img: Branding
        },
        {
            title: `Logo Design`,
            para: `Our expert digital marketing team specializes 
          in crafting exceptional logo designs that leave a 
          lasting impression. We understand the significance of
          a visually striking logo and its role in brand recognition. 
          Trust us to create a logo that embodies your brand's 
          essence and stands out in the digital landscape.`,
            img: LogoDesign
        },
        {
            title: `Social Media Marketing`,
            para: `With our logo-focused social media marketing strategies, 
          we leverage the power of visual storytelling to enhance brand 
          visibility. By strategically incorporating your logo into 
          engaging social media content, we drive brand recognition, 
          foster customer loyalty, and create a cohesive online presence. 
          `,
            img: Market
        },
        {
            title: `SEO`,
            para: `
          Our SEO services are designed to optimize your online presence, 
          increase organic traffic, and boost your search engine rankings. 
          Through meticulous keyword research, on-page optimization, and 
          authoritative link building, we ensure that your website is highly 
          visible to your target audience.`,
            img: Seo
        },
        {
            title: `Web Development`,
            para: `Our web development services offer customized solutions 
          that align with your brand's vision and goals. We create visually 
          appealing, user-friendly websites that deliver seamless experiences 
          across devices. Trust us to build a robust online presence that 
          not only engages your audience but also drives conversions and 
          business growth.`,
            img: WebDevelopment
        },
        {
            title: `App Development`,
            para: `Our app development services deliver cutting-edge solutions 
          tailored to your business needs. With a focus on user experience 
          and functionality, we create intuitive and engaging mobile 
          applications that captivate your audience. Unlock the potential 
          of the mobile market and connect with your customers on-the-go 
          .`,
            img: AppDevelopment
        },
        {
            title: `Email Marketing`,
            para: `Our email marketing strategies drive impactful communication 
          and engagement with your target audience. With personalized and 
          targeted email campaigns, we deliver compelling content directly 
          to your subscribers' inboxes, nurturing relationships and driving 
          conversions. Leverage the power of email marketing to establish brand 
          loyalty and maximize your business growth.`,
            img: Email,
        },
        {
            title: `Influencer Marketing`,
            para: `Our influencer marketing services connect your brand with 
          influential individuals who have a strong online presence and 
          engaged followers. By partnering with relevant influencers, we 
          amplify your brand's reach, increase brand awareness, and drive 
          authentic engagement. Harness the power of influencer marketing 
          to establish trust and credibility among your target audience.`,
            img: Influncer
        },
        {
            title: `Graphics Design`,
            para: `Our graphics design services create visually stunning and 
          impactful designs that captivate your audience. With a keen eye 
          for aesthetics and attention to detail, we craft compelling visual 
          assets that enhance your brand's identity and messaging. Elevate your 
          digital marketing efforts with our expert graphic design solutions 
          and leave a lasting impression on your audience.`,
            img: GraphicsDesign
        },
    ]


    const Query = useMediaQuery('(max-width:1060px)')
    const Query1600 = useMediaQuery('(max-width:1200px)')
    const Query768 = useMediaQuery('(max-width:768px)')
    return (
        <div className='ad-making-page'>
            {/* Section 1  */}

            <section>
                <div className='ad-title-div'>
                    <Center>
                        <Title className='ad-making-title'>
                            Digital Marketing
                        </Title>
                    </Center>
                </div>
                <div>
                    <br />
                    <Grid className='ad-making-grid1'>
                        {digitalMarketing.map((value, index) => {
                            return (
                                <>
                                    <Grid.Col span={Query ? Query768 ? '' : 5 : 4} key={index} className='ad-making-grid1-span'
                                    >
                                        <Card
                                            className='ad-making-grid1-card'
                                            mih={Query768 ? '' : Query1600 ? 545 : 500}
                                            maw={500}
                                            mah={Query ? Query768 ? "auto" : 510 : 500}
                                            shadow="sm" padding="lg" radius="md"
                                            withBorder >
                                            <Card.Section>
                                                <Center>
                                                    <img src={value.img} alt='img' className='ad-marketing-image' />
                                                </Center>
                                            </Card.Section>
                                            <Center>
                                                <Title order={2} className='ad-title'>
                                                    {value.title}
                                                </Title>
                                            </Center>
                                            <Text>
                                                {value.para}
                                            </Text>
                                        </Card>
                                    </Grid.Col>
                                </>
                            )
                        })}
                    </Grid>
                </div>
            </section>
            {/* Section 1  End */}
        </div>
    )
}

export default Marketing
