import React from "react";
import "./App.css"

import Main from "./Main";
import { AppShell } from "@mantine/core";

function App() {
  return (
    <div>
      <Main />
    </div>
  );
}
export default App;
